<template>
  <router-link 
  class="wwtp-link"
  :to="{name: 'WwtpDetails', params: {id: wwtp.id}}">
    <div class="wwtp-card">    
    <h4> {{ wwtp.company }} {{ wwtp.name }} </h4>
    <p> 规模: {{ wwtp.capacity }} </p>
    <p> 处理工艺: {{ wwtp.process }} </p>     
  </div>
  </router-link>

</template>

<script>
export default {
  name: "WwtpCard",
  props: {
    wwtp: {
      type: Object,
      required: true
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wwtp-card {
  padding: 20px;
  width: 250px;
  cursor: pointer;
  border: 1px solid #39495c;
  margin-bottom: 18px;
}

.wwtp-card:hover {
  transform: scale(1.01);
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
}

.wwtp-link {
  color: #2c3e50;
  text-decoration: none;
}
</style>
