<template>

  <div class="form-container">

    <form @submit.prevent="onSubmit">
      <label>选择工艺:</label>
      <select v-model="wwtp.process">
        <option v-for="option in processes" :value="option" :key="option" :selected="option === wwtp.process">
          {{ option }}</option>
      </select>

      <h3>1. 固定设置的数据</h3>

      <label>name</label>
      <input v-model="wwtp.name" type="text" placeholder="name">

      <label>capacity</label>
      <input v-model="wwtp.capacity" type="text" placeholder="capacity" />

      <h3>2. 采集或输入的数据</h3>

      <label>Location</label>
      <input v-model="wwtp.location" type="text" placeholder="Location" />

      <h3>3.校正系数</h3>
      <label>company</label>
      <input v-model="wwtp.company" type="text" placeholder="company" />

      <button type="submit">Submit</button>
    </form>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'

  export default {
    data() {
      return {
        processes: [
          'AAO',
          '多级AO',
          '氧化沟',
          'SBR',
          'MBR',
          '其他'
        ],
        wwtp: {
          id: '',
          process: '',
          name: '',
          capacity: '',
          location: '',
          company: '',
          organizer: ''
        }
      }
    },
    methods: {
      onSubmit() {
        const wwtp = {
          ...this.wwtp,
          id: uuidv4(),
          organizer: this.$store.state.user
        }
        this.$store.dispatch('createWwtp', wwtp)
        .then(() => {
          this.$router.push({
            name: 'WwtpDetails',
            params: { id: wwtp.id }
          })
        })
        .catch(error => {
          this.$router.push({
            name: 'ErrorDisplay',
            params: { error: error }
          })
        })
      }
    }
  }
</script>