import { createStore } from 'vuex'
import WwtpService from '@/services/WwtpService.js'

export default createStore({
  state: {
    user: 'Mingkai Zhang',
    wwtps: [],
    wwtp: {},
  },
  mutations: {
    ADD_WWTP(state, wwtp) {
      state.wwtps.push(wwtp)
    },
    SET_WWTPS(state, wwtps) {
      state.wwtps = wwtps
    },
    SET_WWTP(state, wwtp) {
      state.wwtp = wwtp
    },
  },
  actions: {
    createWwtp({ commit }, wwtp) {
      return WwtpService.postWwtp(wwtp)
        .then(() => {
          commit('ADD_WWTP', wwtp)
        })
        .catch((error) => {
          throw error
        })
    },
    fetchWwtps({ commit }) {
      return WwtpService.getWwtps()
        .then((response) => {
          commit('SET_WWTPS', response.data)
        })
        .catch((error) => {
          throw error
        })
    },
    fetchWwtp({ commit, state }, id) {
      const existingWwtp = state.wwtps.find((wwtp) => wwtp.id === id)
      if (existingWwtp) {
        commit('SET_WWTP', existingWwtp)
      } else {
        return WwtpService.getWwtp(id)
          .then((response) => {
            commit('SET_WWTP', response.data)
          })
          .catch((error) => {
            throw error
          })
      }
    },
  },
  modules: {},
})
