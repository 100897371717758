<template>
  
  <div class="wwtps">
    <WwtpCard v-for='wwtp in wwtps' :key="wwtp.id" :wwtp="wwtp" />
  </div>
</template>

<script>
// @ is an alias to /src
import WwtpCard from "@/components/WwtpCard.vue";

export default {
  name: "WwtpList",
  components: {
    WwtpCard,
  },
  created() {
    this.$store.dispatch('fetchWwtps')
    .catch(error => {
      this.$router.push({
        name: 'ErrorDisplay',
        params: { error: error }
      })
    })
  },
  computed: {
    wwtps() {
      return this.$store.state.wwtps
    }
  }
};
</script>

<style scoped>
.wwtps {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>