import { createRouter, createWebHistory } from 'vue-router'
import WwtpList from '../views/WwtpList.vue'
import WwtpDetails from '@/views/WwtpDetails.vue'
import WwtpCreate from '@/views/WwtpCreate.vue'
import ErrorDisplay from '@/views/ErrorDisplay.vue'

const routes = [
  {
    path: '/',
    name: 'WwtpList',
    component: WwtpList,
  },
  {
    path: '/wwtp/:id',
    name: 'WwtpDetails',
    props: true,
    component: WwtpDetails,
  },
  {
    path: '/wwtp/create',
    name: 'WwtpCreate',
    component: WwtpCreate,
  },
  {
    path: '/error/:error',
    name: 'ErrorDisplay',
    props: true,
    component: ErrorDisplay,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
