import axios from "axios";

const apiClient = axios.create({
  baseURL: "http://127.0.0.1:8000/",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default {
  getWwtps() {
    return apiClient.get("wwtps");
  },
  getWwtp(id) {
    return apiClient.get("wwtps/" + id);
  },
  postWwtp(wwtp) {
    return apiClient.post('wwtps', wwtp)
  }
};
