<template>
  <div v-if="wwtp">
    <h1>{{ wwtp.company }} {{ wwtp.name }}</h1>
    <p> 规模: {{ wwtp.capacity }} </p>
    <p> 处理工艺: {{ wwtp.process }} </p>
    <p> 位置: {{ wwtp.location }} </p>
  </div>
</template>

<script>
export default {
  props: ['id'],
  created() {
    this.$store.dispatch('fetchWwtp', this.id)
    .catch(error => {
      this.$router.push({
        name: 'ErrorDisplay',
        params: { error: error }
      })
    })
  },
  computed: {
    wwtp() {
      return this.$store.state.wwtp
    }
  }
}
</script>